@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
:root {
  --accent-color: #127c7c;
}

::-moz-selection {
  color: #f7f7f7;
  background: var(--accent-color);
}

::selection {
  color: #f7f7f7;
  background: var(--accent-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: #141414;
}

html,
body {
  width: 100%;
  background-color: #f7f7f7;
}

/* .parent {
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
} */

strong {
  font-weight: 600;
  color: var(--accent-color);
}

.landing {
  height: 100vh;
}

.section {
  width: 100%;
  min-height: 100vh;
  scroll-snap-align: start;
  position: relative;
  padding: 3rem;
}

.content {
  display: flex;
  margin: auto;
  width: 80%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1 {
  font-weight: 500;
  font-size: 96px;
  line-height: 96px;
  margin-bottom: 2rem;
}

h2 {
  font-size: 48px;
  line-height: 64px;

  font-weight: 500;
}

p {
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  width: 100%;
}

ul {
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}

ul li {
  font-size: 20px;
  font-weight: 300;
}

.thin {
  font-weight: 200;
}

.highlight {
  font-weight: 600;
  color: var(--accent-color);
}

.content p {
  text-align: center;
  line-height: 32px;
}

.ps {
  transform: translateY(100px);
  font-size: 16px;
  font-weight: 200 !important;
}

.spacer {
  width: 100%;
  height: 200px;
}

.line1,
.line2,
.line3,
.ps {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.about-section {
  height: 90vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 1fr 3fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 3fr 1fr 1fr;
}

.mobile-break {
  display: none;
}

.quote {
  font-size: 18px;
  line-height: 24px;
  width: 80%;
  min-width: 300px;
  margin: auto;
  text-align: center;
  font-style: italic;
}

.hide {
  opacity: 0 !important;
}

.content-grid {
  margin-top: 5rem;
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.project-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem 2rem;
  margin-top: 5rem;
}

.project-card {
  position: relative;
  overflow-y: hidden;
  width: 100%;
  height: auto;
}

.project-card-content {
  position: relative;
  /* top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #f7f7f7;
  transition: opacity 1s ease-in-out; */
  min-height: 450px;
}

.project-description {
  overflow-x: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #f7f7f7;
  transition: opacity 1s ease-out;
  min-height: 450px;
  z-index: 1;
  padding-top: 2rem;
  padding-left: 4px;
  box-shadow: 4px 4px 2px #f7f7f7, -4px -4px 2px #f7f7f7;
}

.project-description:hover {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.project-card-cover {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -1;
  outline: 2px solid #f7f7f7;
  border: 1px solid #f7f7f7;
  box-shadow: -1px -1px -1px #f7f7f7 1px 1px 1px #f7f7f7;
}

.project-card-cover img {
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top left;
     object-position: top left;
}

.project-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.project-card-header {
  margin-bottom: 1rem;
}

.tech-icons {
  display: flex;
  align-items: center;
}

.project-card h2 {
  font-size: 28px;
  line-height: 36px;
}

.project-card p {
  width: 90%;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  margin-bottom: 16px;
}

.section-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.links a img {
  width: 48px;
  height: 48px;
}

form {
  margin-top: 50px;
  margin-left: auto;
  padding: 4rem 0;
  width: 100%;
  max-width: 700px;
  min-width: 500px;
  height: 500px;
}

.form-container {
  display: flex;
  height: 100%;
  grid-gap: 2rem;
}

.form-left,
.form-right {
  height: 100%;
}

.form-left {
  width: 45%;
}

.form-right {
  width: 55%;
}

.form-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form-message {
  height: 100%;
}

label,
input,
textarea {
  font-size: 16px;
  max-width: 300px;
  margin-bottom: 12px;
  outline: none;
  border: none;
  background-color: #f7f7f7;
}

input {
  height: 2rem;
  padding: 0.2rem;
  border-bottom: 2px solid var(--accent-color);
  background-color: #f7f7f7;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #f7f7f7 inset !important;
  -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
}

textarea {
  display: block;
  resize: none;
  width: 100%;
  height: 100%;
  border: 2px solid var(--accent-color);
  border-radius: 4px;
  max-width: unset;
}

.button {
  margin-top: 20px;
  height: unset;
  background-color: var(--accent-color);
  color: #f7f7f7;
  width: 200px;
  padding: 12px;
  border-radius: 4px;
  align-self: flex-end;
}

.tech-icon {
  width: 32px;
  height: 32px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 6px;
}

.menu {
  z-index: 6;
  margin-top: 5vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 12vw;
  min-width: 60px;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  transform: translateX(140%);
  opacity: 1;
  transition: opacity 1s ease-in, right 0.3s ease-in, transform 0.3s ease-in;
}

.menu ul {
  -webkit-padding-start: 4px;
          padding-inline-start: 4px;
}

.menu ul li {
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  margin: 0.5rem 0;
  z-index: 6;
}

.menu ul li a {
  text-decoration: none;
  color: var(--accent-color);
}

.menu-tab {
  z-index: 5;
  position: fixed;
  top: 0;
  right: 0;
  width: 12vw;
  height: 10vh;
  min-width: 60px;
  max-width: 100px;
  min-height: 20px;
  max-height: 60px;
  color: var(--accent-color);
  background: transparent;
  opacity: 1;
  transition: opacity 1s ease-in, right 0.3s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}

.menu-tab:hover {
  cursor: pointer;
}

.menu-active {
  transform: translateX(0);
  transition: opacity 1s ease-in, right 0.3s ease-in, transform 0.3s ease-in;
}

@media (max-width: 1150px) {
  h1 {
    font-size: 72px;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 36px;
    line-height: 40px;
  }
  p {
    font-size: 18px;
    line-height: 26px;
  }

  .content p {
    text-align: center;
    line-height: 26px;
  }
}

@media (max-width: 1000px) {
  h1 {
    font-size: 64px;
    line-height: 70px;
  }
  h2 {
    font-size: 32px;
    line-height: 36px;
  }

  .content {
    width: 100%;
  }
  .project-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 4rem;
  }

  .project-card-cover img {
    -o-object-position: top center;
       object-position: top center;
  }

  .ps {
    transform: translateY(60px);
  }
}

@media (max-width: 767px) {
  p {
    text-align: left;
    width: 100%;
  }
  .spacer {
    height: 50px;
  }

  /* .menu-tab,
  .menu {
    right: 32px;
  } */
}

@media (max-width: 680px) {
  .project-card-content,
  .project-description {
    min-height: 300px;
  }

  .tech-icons {
    flex-wrap: wrap;
  }

  .mobile-break {
    display: inline;
  }

  form {
    margin-top: 0;
    height: unset;
    min-width: unset;
    padding: 1rem 0;
  }
  .form-container {
    flex-direction: column;
    grid-gap: 1rem;
  }

  .form-left,
  .form-right {
    width: 100%;
  }

  textarea {
    height: 200px;
  }

  input {
    max-width: unset;
  }
}

@media (max-width: 550px) {
  .section {
    padding: 1rem;
  }
  h1 {
    font-size: 48px;
    line-height: 52px;
  }
  h2 {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  p {
    font-size: 16px;
    line-height: 22px;
  }

  .quote {
    width: 100%;
    padding: 2rem 0;
    font-size: 14px;
  }

  .content {
    justify-content: space-evenly;
  }
  .content-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
}

/*# sourceMappingURL=index.a0ac5314.css.map */
